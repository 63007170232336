<template>
  <div class="flex justify-center space-x-4">
    <app-button
      v-if="showAddButton"
      emphasis="low"
      feature="add"
      :icon-props="{ size: bigIcons ? 'text-4xl' : null }"
      :label="t('ttmt.common.actions.add_contact')"
      :hide-icon="false"
      hide-text
      @click="handleModalAction('add')"
    />

    <template
      v-if="relation"
    >
      <template
        v-if="!isRelationSender && relation.attributes.state === 'pending'"
      >
        <app-button
          emphasis="low"
          feature="confirm"
          :icon-props="{ size: '2x' }"
          :label="t('ttmt.common.actions.accept_contact')"
          :hide-icon="false"
          hide-text
          @click="handleModalAction('approve')"
        />

        <app-button
          emphasis="low"
          feature="close"
          :icon-props="{ size: 'text-4xl' }"
          :label="t('ttmt.common.actions.decline_contact')"
          :hide-icon="false"
          hide-text
          @click="handleModalAction('decline')"
        />
      </template>
    </template>

    <app-button
      v-if="relation && relation.attributes.state !== 'blocked'"
      emphasis="low"
      feature="block"
      :icon-props="{ size: bigIcons ? '2x' : null }"
      :label="t('ttmt.common.actions.block_contact')"
      :hide-icon="false"
      hide-text
      @click="handleModalAction('block')"
    />

    <report-action
      :resource="resource"
      :emphasis="'low'"
      :hide-icon="false"
      hide-text
      big-icons
    />

    <!-- Modal -->
    <app-overlay
      :show="confirmModalDisplayed"
      @clicked="handleCancel"
    >
      <app-modal @closed="handleCancel">
        <app-confirm
          :confirm-color="colorButton"
          @cancelled="handleCancel"
          @confirmed="handleActionConfirm"
        >
          <p>
            {{ handleTextConfirm }}
          </p>
        </app-confirm>
      </app-modal>
    </app-overlay>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import {
  addContact as addApiContact,
  approveContact as approveApiContact,
  declineContact as declineApiContact,
  blockContact as blockApiContact,
} from '@shared/http/api'
import AppButton from '@shared/components/ui/AppButton.vue'
import AppConfirm from '@shared/components/ui/AppConfirm.vue'
import AppModal from '@shared/components/ui/AppModal.vue'
import AppOverlay from '@shared/components/ui/AppOverlay.vue'
import ReportAction from '@shared/components/resources/user/actions/ReportAction.vue'

const props = defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Display texts
  showLabels: {
    type: Boolean,
    default: false,
  },
  // Display icons bigger
  bigIcons: {
    type: Boolean,
    default: false,
  },
})

const emits = defineEmits([
  'contactUpdated',
])

const { t } = useI18n()
const store = useStore()

const handleActionConfirm = ref()
const handleTextConfirm = ref()
const confirmModalDisplayed = ref(false)
const actionConfirm = ref()
const relation = ref(props.resource.relationships.users_relationship)
const colorButton = ref()

const isRelationSender = computed(() => (
  relation.value && relation.value.attributes.sender_id === store.state.auth.user.id
))

// ---------- BLOCK ----------

function blockContact() {
  store.commit('spinner/ENABLE')

  blockApiContact(relation.value.id)
    .then(() => {
      store.commit(
        'flashes/ADD_FLASH',
        {
          message: t('ttmt.users_relationships.messages.block.success'),
          type: 'success',
        },
      )
    })
    .finally(() => {
      store.commit('spinner/DISABLE')
      emits('contactUpdated')
    })
}

// ---------- ADD CONTACT ----------

const showAddButton = computed(() => (
  (
    !relation.value
    && props.resource.id !== store.state.auth.user.id
  )
  || (
    relation.value
    && relation.value.attributes.state === 'declined'
    && relation.value?.attributes?.decline_expired_at_js_timestamp <= new Date().getTime()
  )
))

function addContact() {
  store.commit('spinner/ENABLE')

  const params = {
    'id': relation.value ? relation.value.id : null,
    'recipient_id': props.resource.id,
  }

  addApiContact(params)
    .then(() => {
      store.commit(
        'flashes/ADD_FLASH',
        {
          message: t('ttmt.users_relationships.messages.add.success'),
          type: 'success',
        },
      )
    })
    .catch((e) => {
      if (e.response.status !== 403) {
        store.commit(
          'flashes/ADD_FLASH',
          {
            message: t('ttmt.users_relationships.messages.add.error'),
            type: 'danger',
          },
        )
      }
    })
    .finally(() => {
      store.commit('spinner/DISABLE')
      emits('contactUpdated')
    })
}

// ---------- APPROVE ----------

function approveContact() {
  store.commit('spinner/ENABLE')

  approveApiContact(relation.value.id)
    .then(() => {
      store.commit(
        'flashes/ADD_FLASH',
        {
          message: t('ttmt.users_relationships.messages.approve.success'),
          type: 'success',
        },
      )
    })
    .finally(() => {
      store.commit('spinner/DISABLE')
      emits('contactUpdated')
    })
}

// ---------- DECLINE ----------

function declineContact() {
  store.commit('spinner/ENABLE')

  declineApiContact(relation.value.id)
    .then(() => {
      store.commit(
        'flashes/ADD_FLASH',
        {
          message: t('ttmt.users_relationships.messages.decline.success'),
          type: 'success',
        },
      )
    })
    .finally(() => {
      store.commit('spinner/DISABLE')
      emits('contactUpdated')
    })
}

// Define action according which button is clicked
function handleModalAction(action) {
  handleTextConfirm.value = t(`ttmt.users_relationships.messages.${action}.confirm`)
  colorButton.value = action === 'approve' || action === 'add' ? 'blue' : 'red'
  actionConfirm.value = action
  handleActionConfirm.value = handleConfirm
  confirmModalDisplayed.value = true
}

function handleConfirm() {
  switch (actionConfirm.value) {
    case 'add':
      addContact()
      break
    case 'approve':
      approveContact()
      break
    case 'decline':
      declineContact()
      break
    case 'block':
      blockContact()
      break
  }
  confirmModalDisplayed.value = false
}

// Cancel modal
function handleCancel() {
  confirmModalDisplayed.value = false
}
</script>
