<template>
  <user-profile-form
    :resource="user"
    :additional-errors="userFormErrors"
    :submitting="userFormSubmitting"
    :mode="mode"
    @submitted="handleSubmit"
  />
</template>

<script setup>
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { ref } from 'vue'

import { updateCurrentUser } from '@shared/http/api'
import UserProfileForm from '@app/components/resources/user/UserProfileForm.vue'
import useAppRouter from '@shared/hooks/router'

const store = useStore()
const appRouter = useAppRouter()
const route = useRoute()
const { t } = useI18n()

const mode = route.query.mode

const userFormSubmitting = ref(false)
const user = ref(store.state.auth.user)
const userFormErrors = ref({})

function handleSubmit(attributes) {
  if (!userFormSubmitting.value) {
    userFormSubmitting.value = true
    const params = {
      data: {
        type: 'user',
        attributes,
      },
    }

    updateCurrentUser(params)
      .then(async () => {
        await store.dispatch('auth/getAuthenticatedUser')
          .then(() => {
            appRouter.back()
            store.commit(
              'flashes/ADD_FLASH',
              {
                message: t('ttmt.users.update.success'),
                type: 'success',
              },
            )
          })
      })
      .catch((e) => {
        if (e.response?.data?.errors) {
          userFormErrors.value = e.response.data.errors
        }
      })
      .finally(() => {
        userFormSubmitting.value = false
      })
  }
}
</script>
